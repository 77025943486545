<script>
import ModuleNavigationBar from '@/components/Modules/ModuleNavigationBar.vue';
import { mapGetters } from 'vuex';
import moment from 'moment/moment';
import InputRulesMixin from '@/Mixins/InputRulesMixin';
import AntInput from '@/components/AntInput.vue';
import { stringToConstantColor } from '@/components/Charts/utils/tasks-chart.utils';
import {
  generateAutomatedPlanning,
  generateManualPlanning,
} from '@/modules/daiwa-house-modular-europe/AssemblyPlanning/dhme-assembly-planning.utils';
import DhmeAssemblyPlanningChart from '@/modules/daiwa-house-modular-europe/AssemblyPlanning/DhmeAssemblyPlanningChart.vue';
import { DHME_PROJECT_ASSEMBLY_PLANNING } from '@/modules/modules';
import { fetchProjectModulesV2 } from '@/services/api/v2/modules.v2.api';
import { fetchProjectV2 } from '@/services/api/v2/projects.v2.api';

export default {
  name: 'AssemblyPlanning',
  components: {
    DhmeAssemblyPlanningChart,
    AntInput,
    ModuleNavigationBar,
  },
  mixins: [InputRulesMixin],
  data: () => {
    return {
      selectedAssemblyHallId: null,
      selectedAssemblyConfiguration: null,
      selectedStartLocation: null,
      manualHallAssemblyGenerationMethod: 'normal',
      automatedTactPerDay: null,
      startDate: null,
      endDate: null,
      startHour: null,
      workHours: null,
      startDateMenu: false,
      endDateMenu: false,
      ganttScale: 3,
      ganttZoomLevels: [
        {
          key: 'year',
          value: 'Yearly',
        },
        {
          key: 'quarter',
          value: 'Quarter',
        },
        {
          key: 'month',
          value: 'Monthly',
        },
        {
          key: 'week',
          value: 'Weekly',
        },
        {
          key: 'day',
          value: 'Daily',
        },
        {
          key: 'hour',
          value: 'Hourly',
        },
      ],
      manualLocationTypeFilter: {},
      dateTab: 'date',
      previousAlignedAssemblyPhase: null,
      generatingPlanning: false,
      locationPlanType: 'automated',
      rules: {
        canDivideHour: (value) =>
          60 % value === 0 || 'Needs to divide 60 evenly.',
      },
      totalLength: null,
    };
  },
  computed: {
    ...mapGetters({
      project: 'project',
      requestedTasks: 'dhmeAssemblyPlanningStore/requestedAssemblyTasks',
      plannedTasks: 'dhmeAssemblyPlanningStore/plannedAssemblyTasks',
      focusedTask: 'dhmeAssemblyPlanningStore/focusedTask',
      focusedHall: 'dhmeAssemblyPlanningStore/focusedHall',
      assemblyHalls: 'dhmeAssemblyPlanningStore/assemblyHalls',
      assemblyConfigurations:
        'dhmeAssemblyPlanningStore/assemblyConfigurations',
      assemblyLocations: 'dhmeAssemblyPlanningStore/assemblyLocations',
      vacationDays: 'dhmeAssemblyPlanningStore/vacationCalendar',
      isLoading: 'dhmeAssemblyPlanningStore/isLoading',
      projectModules: 'dhmeAssemblyPlanningStore/projectModules',
      projectAssemblyTasks: 'dhmeAssemblyPlanningStore/projectAssemblyTasks',
      importCounter: 'dhmeAssemblyPlanningStore/importCounter',
    }),

    configurationsByHall() {
      return this.assemblyConfigurations.filter(
        (c) => c.assembly_hall === this.selectedAssemblyHallId
      );
    },
    configurationLocations() {
      return this.assemblyLocations
        .filter((l) => l.configuration === this.selectedAssemblyConfiguration)
        .sort(
          (a, b) =>
            parseInt(a.assembly_location) - parseInt(b.assembly_location)
        );
    },
    now() {
      return moment().format('YYYY-MM-DD');
    },
    moduleTypes() {
      return [...new Set(this.projectModules.map((m) => m.module_type))];
    },
    selectedAssemblyHall() {
      return this.assemblyHalls.find(
        (h) => h.id === this.selectedAssemblyHallId
      );
    },
    configurationPhases() {
      return this.plannedTasks.filter(
        (t) =>
          t.task_type.custom_4 === this.selectedAssemblyConfiguration &&
          t.task_project.id !== this.focusedTask.task_project.id
      );
    },
  },
  watch: {
    selectedAssemblyHallId(newValue, oldValue) {
      if (newValue && oldValue && newValue !== oldValue) {
        this.selectedAssemblyConfiguration = null;
        this.startDate = null;
        this.startHour = null;
        this.workHours = null;
        this.startDateMenu = false;
        this.manualHallAssemblyGenerationMethod = 'normal';
        this.manualLocationTypeFilter = {};
        this.$refs.assemblyPlanner.reset();
      }
    },
    focusedTask(value) {
      if (value) {
        this.startDate = value.planned_start
          ? moment(value.planned_start).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD');
        this.selectedAssemblyHallId = value.task_type.custom_3;
        this.selectedAssemblyConfiguration = value.task_type.custom_4;
        this.startHour = value.task_type.custom_5;
        this.workHours = value.task_type.custom_6;
        this.manualHallAssemblyGenerationMethod = value.task_type.custom_7;
        const metadata = JSON.parse(value.task_type.metadata);
        this.manualLocationTypeFilter =
          metadata?.manualLocationTypeFilter ?? {};
        this.$store.dispatch('dhmeAssemblyPlanningStore/fetchModulesInOrder');
      }
    },
    ganttScale(value) {
      this.$refs['assembly-hall-gantt'].setZoomLevel(
        this.ganttZoomLevels[value].key
      );
    },
    project: {
      immediate: true,
      handler(newValue) {
        if (newValue) {
          this.$store.dispatch('dhmeAssemblyPlanningStore/fetchAssemblyTasks');
          this.$store.dispatch(
            'dhmeAssemblyPlanningStore/fetchAssemblyHallsAndConfigurations'
          );
        }
      },
    },
  },
  beforeDestroy() {
    this.closePlanner();
  },
  methods: {
    formatTimestamp(timestamp) {
      return moment(timestamp).format('DD:MM:YYYY HH:MM:ss');
    },
    focusOnTask(task) {
      if (task.id !== this.focusedTask?.id) {
        this.$store.commit('dhmeAssemblyPlanningStore/focus_on_task', task);
      } else {
        this.$store.commit('dhmeAssemblyPlanningStore/focus_on_task', null);
      }
    },
    closePlanner() {
      this.selectedAssemblyHallId = null;
      this.selectedAssemblyConfiguration = null;
      this.startDate = null;
      this.startHour = null;
      this.workHours = null;
      this.startDateMenu = false;
      this.manualHallAssemblyGenerationMethod = 'normal';
      this.manualLocationTypeFilter = {};
      this.$refs?.assemblyPlanner?.reset();
      this.$store.commit('dhmeAssemblyPlanningStore/focus_on_task', null);
    },
    async planAssembly() {
      if (this.$refs.assemblyPlanner.validate()) {
        this.generatingPlanning = true;
        const { automated: isAutomated } = this.selectedAssemblyHall;

        if (this.previousAlignedAssemblyPhase) {
          this.startHour = this.previousAlignedAssemblyPhase.task_type.custom_5;
          this.workHours = this.previousAlignedAssemblyPhase.task_type.custom_6;
        }
        let tasks = [];
        if (isAutomated) {
          tasks = await generateAutomatedPlanning(
            [...this.configurationLocations],
            parseInt(this.startHour),
            parseInt(this.workHours),
            this.startDate,
            this.automatedTactPerDay,
            this.projectModules,
            this.selectedAssemblyHallId,
            this.focusedTask,
            this.projectAssemblyTasks,
            this.vacationDays,
            this.previousAlignedAssemblyPhase ?? null
          );
        } else {
          let locations = [...this.configurationLocations];
          if (this.selectedStartLocation) {
            let index = this.configurationLocations.findIndex(
              (x) => x.id === this.selectedStartLocation.id
            );
            // Create two slices using `index` as the pivot, then concatenate them.
            locations = this.configurationLocations
              .slice(index)
              .concat(this.configurationLocations.slice(0, index));
          }

          tasks = await generateManualPlanning(
            locations,
            parseInt(this.startHour),
            parseInt(this.workHours),
            this.startDate,
            this.manualHallAssemblyGenerationMethod,
            this.projectModules,
            this.manualLocationTypeFilter,
            this.focusedTask,
            this.projectAssemblyTasks,
            this.vacationDays,
            this.previousAlignedAssemblyPhase ?? null,
            this.locationPlanType
          );
        }

        this.totalLength = tasks.length;

        const endDate =
          this.locationPlanType === 'automated'
            ? moment
                .max(tasks.map((t) => moment(t.planned_end)))
                .format('YYYY-MM-DD HH:mm:ss')
            : moment(this.endDate)
                .set({
                  hour: parseInt(this.startHour) + parseInt(this.workHours),
                  minute: 0,
                  second: 0,
                  millisecond: 0,
                })
                .format('YYYY-MM-DD HH:mm:ss');

        tasks.unshift({
          id: this.focusedTask.id,
          planned_start: moment
            .min(tasks.map((t) => moment(t.planned_start)))
            .format('YYYY-MM-DD HH:mm:ss'),
          planned_end: endDate,
          due: endDate,
          options: {
            custom_1: 'planned',
            custom_3: this.selectedAssemblyHallId,
            custom_4: this.selectedAssemblyConfiguration,
            custom_5: this.startHour,
            custom_6: this.workHours,
            custom_7: this.manualHallAssemblyGenerationMethod,
            metadata: JSON.stringify({
              manualLocationTypeFilter: this.manualLocationTypeFilter,
            }),
          },
        });

        await this.$store.dispatch(
          'dhmeAssemblyPlanningStore/importAssemblyTasks',
          {
            tasks,
          }
        );
        this.generatingPlanning = false;
        this.closePlanner();
      }
    },
    getLaneColor(location) {
      let l = this.configurationLocations.find(
        (item) => item.assembly_lane === location.assembly_lane
      );
      return stringToConstantColor(`${l.assembly_location}${l.id}`);
    },
    async openProjectAssemblyPlanning() {
      const modules = await fetchProjectModulesV2(
        this.focusedTask.task_project.id
      );
      const module = modules.find(
        (m) => m.route === DHME_PROJECT_ASSEMBLY_PLANNING
      );
      if (module) {
        const project = await fetchProjectV2(this.focusedTask.task_project.id);
        project.modules = modules;
        await this.$store.dispatch('setProject', project);
        await this.$router.push({
          name: DHME_PROJECT_ASSEMBLY_PLANNING,
          params: {
            slug: project.slug,
          },
        });
      } else {
        this.$store.commit('showNotification', {
          content: 'Project assembly planning module not found',
          color: 'warning',
        });
      }
    },
    rejectPlanning() {
      this.$store.dispatch('dhmeAssemblyPlanningStore/rejectPlanning', {
        tasks: [
          {
            id: this.focusedTask.id,
            planned_start: null,
            planned_end: null,
            due: null,
            options: {
              custom_1: 'rejected',
              custom_3: null,
              custom_4: null,
              custom_5: null,
              custom_6: null,
              custom_7: null,
              metadata: null,
            },
          },
        ],
      });
      this.$store.commit('dhmeAssemblyPlanningStore/focus_on_task', null);
    },
  },
};
</script>

<template>
  <div class="d-flex flex-column">
    <module-navigation-bar title="Assembly planning">
      <template slot="module-nav-logo">
        <img
          src="https://www.daiwahousemodular.eu/wp-content/uploads/daiwa_logo.svg"
          height="40px"
          alt=""
        />
      </template>
    </module-navigation-bar>
    <v-progress-linear
      :style="{ opacity: isLoading ? 1 : 0 }"
      color="primary"
      indeterminate
      height="5"
    />
    <div class="flex-1 d-flex pa-5" style="height: 0">
      <div class="overflow-y-auto d-flex flex-column" style="width: 200px">
        <v-subheader class="justify-center">Assembly requests</v-subheader>
        <div
          v-if="requestedTasks.length > 0"
          class="flex-1 overflow-y-auto d-flex flex-column px-2 my-2"
        >
          <v-card
            v-for="task in requestedTasks"
            :key="task.id"
            class="mb-2"
            outlined
            :disabled="generatingPlanning"
            :color="task.id === focusedTask?.id ? 'primary' : ''"
            :dark="task.id === focusedTask?.id"
            max-width="200"
            @click="focusOnTask(task)"
          >
            <v-card-text class="d-flex align-center">
              <v-icon class="mr-2"> mdi-cube</v-icon>
              <div class="mr-2 d-flex flex-column">
                <span>{{ task.task_project.name }}</span>
                <span class="font-italic fs-10">{{
                  task.task_type.custom_2
                }}</span>
                <span class="fs-12 mt-1">{{
                  formatTimestamp(task.created_at)
                }}</span>
              </div>
            </v-card-text>
          </v-card>
        </div>
        <div
          v-else
          class="font-italic d-flex align-center justify-center flex-1"
        >
          No new requests
        </div>
      </div>
      <v-card
        v-if="focusedTask"
        outlined
        :disabled="generatingPlanning"
        class="background-white d-flex flex-column overflow-y-auto"
        style="max-width: 500px"
      >
        <v-card-text class="flex-1 d-flex flex-column flex-scroll-height">
          <div
            v-if="focusedTask.task_type.custom_1 === 'planned'"
            class="mb-5 d-flex align-center"
          >
            <v-chip>{{ focusedTask.task_project.name }}</v-chip>
            <v-spacer />
            <v-tooltip bottom>
              <template #activator="{ on, attrs }">
                <v-btn
                  tile
                  v-bind="attrs"
                  icon
                  @click="openProjectAssemblyPlanning"
                  v-on="on"
                >
                  <v-icon> mdi-arrow-expand-up</v-icon>
                </v-btn>
              </template>
              <span>Project assembly planning</span>
            </v-tooltip>
          </div>
          <v-form
            ref="assemblyPlanner"
            class="flex-1 d-flex flex-column flex-scroll-height"
          >
            <div class="d-flex align-center">
              <ant-input label="Assembly Hall" class="mr-5" top-margin="mt-0">
                <template #input-field>
                  <v-select
                    v-model="selectedAssemblyHallId"
                    :items="assemblyHalls"
                    placeholder="hall"
                    dense
                    filled
                    :rules="[rules.required]"
                    item-text="title"
                    item-value="id"
                  >
                    <template #item="{ item }">
                      {{ item.title }}
                      <v-spacer />
                      <v-chip class="mx-2" small>
                        <v-icon left small
                          >{{
                            item.automated
                              ? 'mdi-robot-industrial'
                              : 'mdi-account-wrench'
                          }}
                        </v-icon>
                        {{ item.automated ? 'Automated' : 'Manual' }}
                      </v-chip>
                    </template>
                  </v-select>
                </template>
              </ant-input>

              <ant-input label="Configuration" top-margin="mt-0">
                <template #input-field>
                  <v-select
                    v-model="selectedAssemblyConfiguration"
                    :items="configurationsByHall"
                    :disabled="!selectedAssemblyHallId"
                    placeholder="Configuration"
                    dense
                    filled
                    :rules="[rules.required]"
                    item-text="title"
                    item-value="id"
                  />
                </template>
              </ant-input>
            </div>
            <v-btn-toggle
              v-if="
                !selectedAssemblyHall?.automated &&
                selectedAssemblyHallId &&
                selectedAssemblyConfiguration
              "
              v-model="locationPlanType"
              tile
              color="primary"
              group
              mandatory
            >
              <v-btn value="automated" class="flex-1"> Automated</v-btn>

              <v-btn value="manual" class="flex-1"> Manual</v-btn>
            </v-btn-toggle>
            <v-subheader
              v-if="
                selectedAssemblyHallId &&
                selectedAssemblyConfiguration &&
                !selectedAssemblyHall?.automated
              "
              class="mb-5"
            >
              {{
                locationPlanType === 'automated'
                  ? 'Locations are determined by the system in the most optimal way'
                  : 'Locations are manually defined at moment of assembly'
              }}
            </v-subheader>
            <div
              v-if="selectedAssemblyHallId && selectedAssemblyConfiguration"
              class="d-flex flex-column flex-1 flex-scroll-height"
            >
              <div v-if="locationPlanType === 'automated'" class="d-flex">
                <v-btn-toggle
                  v-model="dateTab"
                  tile
                  color="primary"
                  group
                  mandatory
                >
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn value="date" small v-bind="attrs" v-on="on">
                        Date
                      </v-btn>
                    </template>
                    <span
                      >Pick a date and time when the assembly will start in the
                      hall</span
                    >
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template #activator="{ on, attrs }">
                      <v-btn value="project" small v-bind="attrs" v-on="on">
                        Project
                      </v-btn>
                    </template>
                    <span
                      >Select the project where the current planning will align
                      with at the end</span
                    >
                  </v-tooltip>
                </v-btn-toggle>
              </div>

              <div v-if="dateTab === 'date'">
                <div class="d-flex">
                  <ant-input label="Start current" class="flex-grow-1">
                    <template #input-field>
                      <v-menu
                        v-model="startDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            v-model="startDate"
                            readonly
                            v-bind="attrs"
                            prepend-inner-icon="mdi-calendar"
                            :rules="[rules.required]"
                            placeholder="2024-01-01"
                            hide-details
                            dense
                            filled
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="startDate"
                          :min="now"
                          @input="startDateMenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </template>
                  </ant-input>
                  <ant-input
                    v-if="locationPlanType === 'manual'"
                    label="Expected End"
                    class="flex-grow-1 ml-5"
                  >
                    <template #input-field>
                      <v-menu
                        v-model="endDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template #activator="{ on, attrs }">
                          <v-text-field
                            v-model="endDate"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            :rules="[rules.required]"
                            placeholder="2024-01-01"
                            hide-details
                            dense
                            filled
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="endDate"
                          :min="startDate"
                          @input="endDateMenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </template>
                  </ant-input>
                </div>
                <div class="d-flex">
                  <ant-input label="Start hour" class="mr-5 flex-grow-1">
                    <template #input-field>
                      <v-text-field
                        v-model="startHour"
                        placeholder="7"
                        hide-details
                        :rules="[rules.required]"
                        dense
                        type="number"
                        filled
                      />
                    </template>
                  </ant-input>
                  <ant-input label="Work hours" class="flex-grow-1">
                    <template #input-field>
                      <v-text-field
                        v-model="workHours"
                        placeholder="8"
                        :rules="[rules.required]"
                        hide-details
                        dense
                        type="number"
                        filled
                      />
                    </template>
                  </ant-input>
                </div>
              </div>
              <div v-if="dateTab === 'project'">
                <ant-input label="Project">
                  <template #input-field>
                    <v-select
                      v-model="previousAlignedAssemblyPhase"
                      :items="configurationPhases"
                      placeholder="project"
                      :rules="[rules.required]"
                      dense
                      hide-details
                      filled
                      item-text="task_project.name"
                      return-object
                    />
                  </template>
                </ant-input>
              </div>
              <div
                v-if="
                  !selectedAssemblyHall?.automated &&
                  locationPlanType === 'automated'
                "
                class="d-flex flex-column flex-1 flex-scroll-height"
              >
                <ant-input label="Method">
                  <template #input-field>
                    <v-select
                      v-model="manualHallAssemblyGenerationMethod"
                      :items="['normal', 'pendulum', 'waterfall']"
                      placeholder="normal"
                      :rules="[rules.required]"
                      dense
                      filled
                    />
                  </template>
                </ant-input>

                <div
                  v-if="locationPlanType === 'automated'"
                  class="flex-1 flex-scroll-height d-flex flex-column"
                >
                  <v-subheader class="mb-5">
                    Here, you can decide which types can only be assembled on
                    specific locations. By default, all types can be assembled
                    anywhere.
                  </v-subheader>
                  <div class="flex-1 flex-scroll-height overflow-y-auto py-2">
                    <div
                      v-for="location in configurationLocations"
                      :key="location.id"
                      :class="
                        manualHallAssemblyGenerationMethod === 'pendulum' &&
                        parseInt(location.assembly_lane) % 2 === 0
                          ? 'flex-row-reverse'
                          : 'flex-row'
                      "
                      class="d-flex align-center px-2 py-1 mb-2"
                      style="height: 50px"
                    >
                      <div
                        :style="{ 'background-color': getLaneColor(location) }"
                        class="lane-color-block mx-2"
                      />
                      {{ location.assembly_location }}
                      <v-select
                        v-model="manualLocationTypeFilter[location.id]"
                        :items="moduleTypes"
                        chips
                        class="mx-5"
                        clearable
                        dense
                        filled
                        hide-details
                        multiple
                        placeholder="Type (optional)"
                        small-chips
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="selectedAssemblyHall?.automated"
                class="flex-1 d-flex flex-column flex-scroll-height"
              >
                <ant-input label="Tacts per day">
                  <template #input-field>
                    <v-text-field
                      v-model="automatedTactPerDay"
                      placeholder="10"
                      :rules="[rules.required, rules.canDivideHour]"
                      dense
                      filled
                      type="number"
                    />
                  </template>
                </ant-input>
              </div>
              <div
                v-if="
                  !selectedAssemblyHall?.automated &&
                  locationPlanType === 'automated'
                "
                class="d-flex flex-column flex-scroll-height"
              >
                <ant-input
                  label="Start on location"
                  is-optional
                  hint="Set the location from where the assembly planning is started (default is the first location)"
                >
                  <template #input-field>
                    <v-select
                      v-model="selectedStartLocation"
                      :items="configurationLocations"
                      :disabled="!selectedAssemblyConfiguration"
                      placeholder="Location"
                      dense
                      filled
                      return-object
                      item-text="assembly_location"
                    >
                      <template #item="{ item }">
                        {{ item.assembly_location }}
                        <v-spacer />
                        <span class="fs-10 font-italic">{{ item.order }}</span>
                      </template>
                    </v-select>
                  </template>
                </ant-input>
              </div>
            </div>
            <div
              v-if="selectedAssemblyHallId && selectedAssemblyConfiguration"
              class="d-flex align-center mt-5"
            >
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <div v-bind="attrs" class="mr-5" v-on="on">
                    <v-icon class="mr-2">mdi-account-wrench</v-icon>
                    {{ configurationLocations.length }}
                  </div>
                </template>
                <span>Locations</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <div v-bind="attrs" class="mr-5" v-on="on">
                    <v-icon class="mr-2">mdi-cube</v-icon>
                    {{ projectModules.length }}
                  </div>
                </template>
                <span>Modules</span>
              </v-tooltip>
              <span v-if="generatingPlanning">
                {{ importCounter }}/{{ totalLength }}
              </span>
              <v-spacer />
              <v-btn
                text
                color="error"
                class="mr-5"
                :disabled="generatingPlanning"
                @click="closePlanner"
                >Cancel
              </v-btn>
              <v-btn
                elevation="0"
                color="primary"
                :loading="generatingPlanning"
                :disabled="generatingPlanning"
                @click="planAssembly"
                >Plan
              </v-btn>
            </div>
            <v-btn
              v-if="focusedTask.task_type.custom_1 === 'planned'"
              color="warning"
              small
              class="mt-5"
              elevation="0"
              @click="rejectPlanning"
              >Reject planning</v-btn
            >
          </v-form>
        </v-card-text>
      </v-card>

      <div class="flex-1 d-flex flex-column ml-5" style="width: 0">
        <div class="d-flex mb-5 align-center px-2">
          <v-chip
            v-if="focusedHall"
            close
            @click:close="
              $store.commit('dhmeAssemblyPlanningStore/focus_on_hall', null)
            "
          >
            <v-icon
              >{{
                focusedHall.automated
                  ? 'mdi-robot-industrial'
                  : 'mdi-account-wrench'
              }}
            </v-icon>
            {{ focusedHall.title }}
          </v-chip>
          <v-spacer />
          <v-btn-toggle
            v-model="ganttScale"
            mandatory
            color="primary"
            rounded
            dense
          >
            <v-btn v-for="option in ganttZoomLevels" :key="option.key">
              {{ option.value }}
            </v-btn>
          </v-btn-toggle>
        </div>
        <dhme-assembly-planning-chart ref="assembly-hall-gantt" />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.lane-color-block {
  width: 5px;
  height: 100%;
  border-radius: 15px;
}
</style>
